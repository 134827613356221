import React, { useState, useEffect } from "react"
import { Grid, Box } from '@mui/material';
import Layout from '../../components/Layout'
import iconInstagram from '../../images/icon-instagram.svg'
import iconOpensea from '../../images/icon-opensea.svg'
import iconTiktok from '../../images/icon-tiktok.svg'
import nft5 from '../../images/nftforgood_nft5.png'
import nft2 from '../../images/nftforgood_nft2.png'
import nft3 from '../../images/nftforgood_nft3.png'
import nft4 from '../../images/nftforgood_nft4.png'
import './styles.scss';
//ex: https://polytrade.finance/


const NftforgoodPage = () => {
  const [ activeDrawer, setActiveDrawer] = useState(0);
  const calculateTimeLeft = () => {
    let year = new Date().getFullYear();
    const difference = +new Date(`${year}-11-21`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [year] = useState(new Date().getFullYear());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  const onClickDrawer = (index) => () => {
    setActiveDrawer(index)
  }
  return (
    <div className="nftforgood">
      <nav>
        <h1><a href="/">NFTS FOR GOOD</a></h1>
        {/* <div>
          <a href="https://opensea.io/MisterJey" target="_blank"><img style={iconStyles2} src={iconOpensea} /></a>
          <a href="https://www.instagram.com/misterjeyofficial/" target="_blank"><img style={iconStyles} src={iconInstagram} /></a>
          <a href="https://www.tiktok.com/@misterjeyofficial" target="_blank"><img style={iconStyles} src={iconTiktok} /></a>
        </div> */}
      </nav>
      <div className="content">
        <div className="hero">
          <div className="intro">
            <h1>Welcome TO <br />NFT for good</h1>
            <p>A collective NFT project that will redefine fundraising.</p>
          </div>
          <div className="framed-nft">
              <img src={nft5} />
              <img src={nft2} />
              <img src={nft3} />
              <img src={nft4} />
          </div>
        </div>

        <section className="goals">
          <h2>What we choose to fight against</h2>
          <p>There is so many things that is going wrong in this world. We can't fight againt everything at one. Here's the list of topic we choose to focus on right now. The list will grow along with the project.</p>
          <ul>
            <li>- Police violance & racial profiling</li>
            <li>- Hate crime againts people of colors</li>
            <li>- Women and children victims of domestic violence</li>
            <li>- Child abuse</li>
          </ul>
        </section>


        <section className="faw">
          <h2>Key Benefits</h2>
          <Grid container spacing={2}>
            <Grid item xs={4}>
            <h3>For ONG</h3>
              <ul>
                <li>Never ending founding</li>
                <li>Exposure</li>
                <li>Completly free. The purpose is to give, not to take...</li>
              </ul>
            </Grid>
            <Grid item xs={4}>
            <h3>For artists</h3>
              <ul>
                <li>Free NFTs</li>
                <li>Exposure</li>
                <li>First dip on special edition NFT made with other artists.</li>
                <li>Access to special event</li>
              </ul>
            </Grid>
            <Grid item xs={4}>
              <h3>For Contributer</h3>
                <ul>
                  <li>Helping others while collecting NFT</li>
                  <li>First dip on special edition NFT made in colab with artists.</li>
                  <li>Access to special event</li>
                </ul>
            </Grid>
          </Grid>
        </section>
    
      {/*
      <div className="background-dark">
        <section>
          <h2>Our Team</h2>
          <ul>
            <li>Mistey Jey - founder and super geek</li>
            <li>Alfa Barry - awesome advisor</li>
          </ul>
        </section>
      </div> */}
      {/* 
        <div className="background-dark">
          <section>
            <h2>Roadmap</h2>
            <p>TBD</p>
          </section>
        </div>
      */}

        <section className="roadmap">
          <h2>Roadmap</h2>
          <ul>
            <li>Start selling the new NFT collection</li>
            <li>Colab with artists in order to create limited edition NFT</li>
            <li>Mech store</li>
            <li>Live fundraiser event with DJ in a metaverse</li>
            <li>🤷🏻 the community will decide</li>
          </ul>
        </section>

        <section className="faq">
          <h2>FAQ</h2>
          <h3 onClick={onClickDrawer(0)}>Why choosing NFT's for this project?</h3>

          <div className={`drawer ${activeDrawer === 0 ? 'active' : ''}`}>
            <p>We are currently living the biggest technological revolution we ever seen. The world is changing right now and we don't want to be left behind. NFTs have unlimited potential and it's up to us to defined how we will use it in the future.</p>
          </div>
          
          <h3 onClick={onClickDrawer(1)}>What are NFTs?</h3>
          <div className={`drawer ${activeDrawer === 1 ? 'active' : ''}`}>
            <p>WNFT stands for Non-fungible token. “Non-fungible” more or less means that it’s unique and can’t be replaced with something else. </p>
            <p>Imagine a painting: there is only one physical object. Digital “things” can’t have the same kind of uniqueness, because every version of a jpg is the same, there is no “original”. </p>
            <p>So NFT is a technology to create a kind of certificate of uniqueness that is tied to any object, image, sound or anything really. It’s like a notarized ownership certificate for whatever you want. </p>
          </div>

          <h3 onClick={onClickDrawer(2)}>Is it like BitCoin?</h3>
          <div className={`drawer ${activeDrawer === 2 ? 'active' : ''}`}>
            <p>A bitcoin is fungible — trade one for another bitcoin, and you’ll have exactly the same thing. A one-of-a-kind trading card, however, is non-fungible. If you traded it for a different card, you’d have something completely different. </p>
          </div>

          <h3 onClick={onClickDrawer(3)}>How much do NFTs cost?</h3>
          <div className={`drawer ${activeDrawer === 3 ? 'active' : ''}`}>
            <p>Each NFT is unique, so they all have different values. Like with any asset, its price is determined by demand and supply.</p>
            <p>Since there is only one of each NFT, the scarcity, combined with the trend towards digital collectibles, is resulting in some of them being sold for a lot of money.</p>
            <p>For example, an NFT for a picture of a cat was sold by Paris Hilton for around $17,000. The most expensive NFT so far is a piece of digital art sold for over sixty nine million dollars.</p>
          </div>

          <h3 onClick={onClickDrawer(4)}>How are NFTs created?</h3>
          <div className={`drawer ${activeDrawer === 4 ? 'active' : ''}`}>
            <p>If you want to create an NFT, you have to first choose a specific blockchain technology, and upload your content to that blockchain… or ask Mister Jey to do it for you.</p>
            <p>Then, you select the NFT marketplace to list your NFT on, since NFTs can only be bought and sold on specialized NFT marketplaces.</p>
            <p>You can even add a royalty or commission to your NFT, which would result in a payment to you every time your NFT changes hands.</p>
          </div>

          <h3 onClick={onClickDrawer(5)}>How are NFTs bought & sold?</h3>
          <div className={`drawer ${activeDrawer === 5 ? 'active' : ''}`}>
            <p>In order to buy or sell an NFT, you need a digital wallet specific to that marketplace, which needs to be loaded with the cryptocurrency accepted at that marketplace. But, then again, Mister Jey got you covered. He will do it and send you the profit directly to your bank account.</p>
          </div>
        </section>
      </div>
    </div>
  )
}

export default NftforgoodPage
